import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";
import { TransactionTable } from "../../Fund"
import NumberFormat from 'react-number-format';

import { Popover, Checkbox, Table, Form, Input, Divider, Select, Button, InputNumber, message, Radio, Switch, Typography, Modal, Alert, Descriptions, Spin, Tooltip, Icon } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const MONTHS = ["May", "June", "July", "August"];

const TransactionMoveForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      endpoint_transaction: "/api/funds/transactions/",
      endpoint_payroll: "/api/funds/payroll/",
      from_index: null,
      to_index: null,
      justification: null,
      payrolls: [],
      transactions: [],

      loading_transactions: false,
      loading_payrolls: false,
      all_transactions: [],
      all_payrolls: [],
      error: null,
    }

    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    componentDidMount() {
      this.reset();
    }

    getTransactions = () => {
      const { from_index } = this.state;

      if (from_index) {
        const from_date = moment().subtract(6, 'months');

        this.setState({ loading_payrolls: true, loading_transactions: true }, () => {
          this.doGet(this.state.endpoint_transaction + "?index=" + from_index + "&revenue_amount__isnull=False&report__number__final=True&date__gt=" + from_date.format("YYYY-MM-DD"), data => { this.setState({ all_transactions: data, loading_transactions: false }) }),
            this.doGet(this.state.endpoint_payroll + "?index=" + from_index + "&report__number__final=True&pay_date__gt=" + from_date.format("YYYY-MM-DD"), data => { this.setState({ all_payrolls: data, loading_payrolls: false }) })
        });
      }
    }

    reset = () => {
      this.setState({ from_index: null, to_index: null, justification: null, transactions: [], payrolls: [], error: null });
      this.props.form.resetFields();
    }

    validateForm = () => {
      const { payrolls, transactions } = this.state;

      if (payrolls.length == 0 && transactions.length == 0) {
        this.setState({ error: "You must pick at least one transaction." });
      }

      return payrolls?.length > 0 || transactions?.length > 0;
    }

    handleSubmit = () => {
      const { semesters, user } = this.props;
      const { from_index, to_index,  justification, transactions, payrolls } = this.state;

      if (this.validateForm()) {
        const data = { instructor: this.props.user.instructor, from_index: from_index, justification: justification, transaction_set: transactions, payrolltransaction_set: payrolls, transactions: [], payrolltransactions: [] };
        to_index == -1 ? null : data["to_index"] = to_index;
        this.props.onSubmit(data, this.reset);
      }
    }

    render() {
      const { visible, onCancel, onCreate, form, item, sections, campus, semester, month, user } = this.props;
      const { getFieldDecorator } = form;
      const { error, from_index, to_index, payrolls, transactions, justification, loading_payrolls, loading_transactions, all_payrolls, all_transactions } = this.state;

      const fundlist = this.fund_list().filter(f => f.active && f.owners.find(fo => fo.owner == this.props.user.employee));

      const my_transactions = all_transactions.filter(t => t.rule != "GRIC" && t.rule != "PF01" && t.rule != "HGNL");
      const my_payrolls = this.translate_payroll_to_transactions(all_payrolls);

      return (
        <>
          <p>Please use the form below to request that transactions be moved from one index to another. If you have any questions or concerns, or if an index that you expect to see does not show up, please contact <a href="mailto:khoury-grants@northeastern.edu">Khoury Grants Administration</a>.</p>
          <p>Note that you can only move charges that posted to the index within the past six months.  If you need to move a charge that posted before that time, please contact <a href="mailto:khoury-grants@northeastern.edu">Khoury Grants Administration</a>.</p>
          {error ? (<div><Alert message={error} type="error" showIcon /><p /></div>) : ""}
          <Form>
            <FormItem {...this.formItemLayout} label="From Index" extra={"Please select the index you wish to move transactions from."}>
              {getFieldDecorator('from_index', {
                initialValue: from_index,
                rules: [{ required: true, message: 'Please select the index to transfer from.' }],
                onChange: (event) => { this.setState({ from_index: event, transactions: [], payrolls: [], to_index: null }, this.getTransactions) },
              })(<Select showSearch style={{ width: 750 }} filterOption={this.filter}>
                {fundlist.map(f => (<Option value={f.id}>{[this.print_full_fund(f.id), " (", <NumberFormat value={f.balance} prefix="$" thousandSeparator="," displayType='text' decimalScale={2} fixedDecimalScale={true} />, ")"]}</Option>))}
              </Select>
              )}
            </FormItem>

            {from_index ? (
                <FormItem {...this.formItemLayout} label="To index" extra={"Please select the index you wish to move transactions to."}>
                  {getFieldDecorator('to_index', {
                    initialValue: to_index,
                    rules: [{ required: true, message: 'Please select the index to transfer to.' }],
                    onChange: (event) => { this.setState({ to_index: event }) },
                  })(<Select showSearch style={{ width: 750 }} filterOption={this.filter}>
                    <Option value={-1}>These charges are not mine</Option>
                    {fundlist.filter(f => f.id != from_index).map(f => (<Option value={f.id}>{[this.print_full_fund(f.id), " (", <NumberFormat value={f.balance} prefix="$" thousandSeparator="," displayType='text' decimalScale={2} fixedDecimalScale={true} />, ")"]}</Option>))}
                  </Select>
                  )}
                </FormItem>
              ) : null}

            {from_index && to_index ? loading_payrolls || loading_transactions ? <Spin tip="Loading transactions..." /> : (
              <>
                <FormItem {...this.formItemLayout} label="Payroll" extra={"Please select the payroll items you wish to move."}>
                  <div style={{ width: 750 }}>
                    <TransactionTable {...this.props} selected={l => this.setState({ payrolls: l })} fund={this.get_fund(from_index)} transactions={my_payrolls} payroll={[]} show_commitments={false} nogroup={true} />
                  </div>
                </FormItem>
                <FormItem {...this.formItemLayout} label="Other Transactions" extra={"Please select the non-payroll transactions you wish to move."}>
                  <div style={{ width: 750 }}>
                    <TransactionTable {...this.props} selected={l => this.setState({ transactions: l })} fund={this.get_fund(from_index)} transactions={my_transactions} payroll={[]} show_commitments={false} nogroup={true} />
                  </div>
                </FormItem>
                <FormItem {...this.formItemLayout} label="Justification" extra="Please provide details about why these transactions need to be moved. If the transaction is older than 90 days, extra details are required.">
                  {getFieldDecorator('justification', {
                    rules: [{ required: true, message: 'Please provide details about why these transactions need to be moved.' }],
                    onChange: (event) => { this.setState({ justification: event.target.value }) },
                  })(<TextArea rows={4} style={{ width: 750 }} />
                  )}
                </FormItem>
              </>
            ) : null}

            <FormItem {...this.formItemLayout} label=" " colon={false}><Button disabled={(justification == null) || (to_index == null) || ((payrolls.length == 0) && (transactions.length == 0))} type="primary" onClick={this.handleSubmit}>Submit</Button></FormItem>
          </Form>
        </>
      );
    }
  }
);

class TransactionMove extends AppComponent {


  fundFormatter = (fund) => {
    let specificFund = this.get_fund(fund)
    return (
      specificFund && specificFund.budget_end ? <Tooltip title={"Fund End Date: " + moment(specificFund.budget_end).format("YYYY-MM-DD")}>{this.print_fund(fund) + " "}<Icon type="info-circle" /></Tooltip> : this.print_fund(fund)
    );

  }

  render() {
    const { record } = this.props;
    const item = record.record;

    return (
      <Descriptions bordered title={"Transaction move request"}>
        <Descriptions.Item label="Requestor">{this.link_full_instructor(item.instructor)}</Descriptions.Item>
        <Descriptions.Item label="From Index">{this.fundFormatter(item.from_index)}</Descriptions.Item>
        <Descriptions.Item label="To Index">{item.to_index ? this.fundFormatter(item.to_index) : "Charges marked as unknown"}</Descriptions.Item>
        <Descriptions.Item label="Submitted">{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Justification" span={2}>{item.justification ? item.justification : <i>No justification provided</i>}</Descriptions.Item>
        <Descriptions.Item label="Payroll" span={3}><>{
          <TransactionTable {...this.props} brief={true} fund={this.get_fund(item.from_index)} transactions={this.translate_payroll_to_transactions(item.payrolltransactions)} payroll={[]} show_commitments={false} nogroup={true} />
        }</></Descriptions.Item>
        <Descriptions.Item label="Other Transactions" span={3}><>{
          <TransactionTable {...this.props} brief={true} fund={this.get_fund(item.from_index)} transactions={item.transactions} payroll={[]} show_commitments={false} nogroup={true} />
        }</></Descriptions.Item>
      </Descriptions>
    );
  }
}

class TransactionMoveTable extends WorkflowTable {
  get_columns = () => {
    return [{
      title: "Requestor",
      align: 'left',
      width: 110,
      render: (text, record, idx) => this.link_instructor(record.record.instructor)
    }, {
      title: "From Index",
      align: 'left',
      render: (text, record, idx) => this.print_full_fund(record.record.from_index),
    }, {
      title: "To Index",
      align: 'left',
      render: (text, record, idx) => record.record.to_index ? this.print_full_fund(record.record.to_index) : "Charges marked as unknown",
    }, {
      title: "Total (exc. overhead/fringe)",
      align: 'left',
      render: (text, record, idx) => <NumberFormat value={record.record.transactions.reduce((a, r) => a + r.revenue_amount, 0) + record.record.payrolltransactions.reduce((a, r) => a + r.amount, 0)} prefix="$" thousandSeparator="," displayType='text' decimalScale={2} fixedDecimalScale={true} />
    }];
  }
}

class TransactionMoveOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/faculty/transactionmove/",
    visible: true,
  }

  getYear = () => {
    const { semesters } = this.props;
    return this.get_semester(semesters[0]).year;
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "Transaction Move Request";
  }

  get_workflowtype = () => {
    return "transactionmove";
  }

  get_name_plural = () => {
    return "Transaction Move Requests";
  }

  get_breadcrumbs = () => {
    return [{ link: "/faculty", text: "Faculty" }, { text: "Transaction Move" }];
  }

  get_form = (func) => {
    <TransactionMoveForm {...this.props} onSubmit={this.submit_form} />
  }

  get_record_view = (record) => {
    return <TransactionMove {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func(); }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <div>
        <p>In the table below, please see a list of all of your submissions for moving transactions.  Once you submit a proposal, it is routed to the Khoury Grants group and then to the Khoury Finance if necessary.  The Khoury Grants group will verify that the transfer is allowed, and will initiate the changes.  Once submitted, they should be reflected in a few business days.</p>
      </div>
    );
  }
}

export { TransactionMoveOverview, TransactionMove, TransactionMoveTable };
